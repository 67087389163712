@import 'src/styles/core.sass'
[id="landing"].page
  overflow-x: hidden
  .landing-section
    padding: 10vh 0
    position: relative
    +breakpoint(laptop)
      padding: 15vh 0
    &#landing-hero-banner
      padding: 0
  .container
    +breakpoint(smartTV)
      max-width: $desktop
      margin: auto
