@import 'src/styles/core.sass'
.social-links
  display: flex
  gap: 15px
  justify-content: center
  align-items: center
  width: max-content
  .icon
    font-size: $font-size-l
    line-height: 1
    &:hover
      color: #0F2B41
    +breakpoint(laptop)
      font-size: $font-size-xl
