+font-face("icons-mobile", "/fonts/icons/icons-mobile", normal, normal)

[class^="mobile-icon-"], [class*=" mobile-icon-"]
  font-family: "icons-mobile"
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: inherit
  // Better Font Rendering =========== //
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  display: inline-block
  vertical-align: baseline
  text-decoration: inherit

  text-align: center
  // font-size: 120%

  // margin-right: .1em
  // Animation center compensation - margins should be symmetric remove if not needed
  // margin-left: .1em

  // Uncomment for 3D effect
  // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3)

  // Avoid text selection
  user-select: none

.mobile-icon
  font-family: "icons-mobile"

.mobile-icon-failed:before
  content: "\e900"

.mobile-icon-thunder:before, .mobile-icon-active:before
  content: "\e901"

.mobile-icon-ribbon:before
  content: "\e902"

.mobile-icon-compass:before, .mobile-icon-explore:before
  content: "\e903"

.mobile-icon-wallet:before, .mobile-icon-liquidated:before
  content: "\e905"

.mobile-icon-copy:before
  content: "\e904"
