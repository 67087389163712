@import 'src/styles/core.sass'
[id="ai-section"]
  position: relative
  background-image: linear-gradient(180deg, #F2B125 12%, #FFEBA9 75%, #FFFCE8 100%)
  height: 110vh
  @supports (height: 110dvh)
    height: 110dvh
  +breakpoint(laptop)
    height: 180vh
  h1
    font-weight: normal
  &::before
    content: ''
    position: absolute
    background-image: url('/images/landing/new-hero-banner/ai-bg.png')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    mix-blend-mode: soft-light
    opacity: 0.25
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1
  .rocks-column
    position: absolute
    background-image: url('/images/landing/new-hero-banner/rocks/rocks-4.png')
    background-size: contain
    background-position: left
    background-repeat: no-repeat
    animation: float 4.2s ease-in-out .17s infinite
    height: 160vh
    width: 50%
    top: 4%
    right: 7%
    z-index: 1
  div.clouds-wrapper
    bottom: calc(15vh - 3px)
    @supports (height: 25dvh)
      bottom: calc(15dvh - 3px)
    +breakpoint(laptop)
      bottom: calc(25vh - 3px)
      height: 15vh
  .discover-ai-button
    border-radius: 8px
    font-weight: 600
    padding: 10px 16px
    border: none
    color: $white
    background-color: #32A9F9
    display: flex
    align-items: center
    justify-content: center
    gap: 6px
    width: max-content
    +event
      color: white
  .container
    position: relative
    &::before
      display: block
      content: ''
      position: absolute
      left: -30%
      top: 0
      height: 100%
      width: 160%
      background-image: url('/images/landing/new-hero-banner/rocks/rocks-5.png')
      background-size: contain
      background-position: center
      background-repeat: no-repeat
      animation: float 4s ease-in-out .69s infinite
      z-index: -1
  .wrapper
    pointer-events: none
    transition: all 0.3s ease
    background-color: rgba(#FEFFDD, 0.3)
    backdrop-filter: blur(4px)
    border-radius: 16px
    padding: 20px
    margin-top: 0
    a
      margin: auto
    +breakpoint(laptop)
      transform: translateY(-50%)
      background-color: rgba(#FEFFDD, 0)
      backdrop-filter: blur(0)
      position: fixed
      opacity: 0
      translate: -5%
      z-index: 1
      top: 50%
      a
        margin-left: 0
    &.enter
      translate: 0
      opacity: 1
      a
        pointer-events: auto
  .content-right
    .rocks
      background-size: contain
      height: 90vh
      width: 100%
      background-repeat: no-repeat
