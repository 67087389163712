+font-face("icons", "/fonts/icons/icons", normal, normal)

[class^="icon-"], [class*=" icon-"]
  font-family: "icons"
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: inherit
  // Better Font Rendering =========== //
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  display: inline-block
  vertical-align: baseline
  text-decoration: inherit

  text-align: center
  // font-size: 120%

  // margin-right: .1em
  // Animation center compensation - margins should be symmetric remove if not needed
  // margin-left: .1em

  // Uncomment for 3D effect
  // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3)

  // Avoid text selection
  user-select: none

.icon
  font-family: "icons"

.icon-discord:before
  content: "\e900"

.icon-emoji:before
  content: "\e901"

.icon-arrow-right:before
  content: "\e902"

.icon-triangle_down:before
  content: "\e903"

.icon-triangle_up:before
  content: "\e904"

.icon-close:before
  content: "\e905"

.icon-angle_down:before
  content: "\e906"

.icon-angle_right:before
  content: "\e907"

.icon-angle_up:before
  content: "\e908"

.icon-angle_left:before
  content: "\e909"

.icon-exclamation:before
  content: "\e90a"

.icon-exclamation-warning:before
  content: "\e90a"

.icon-gitbook:before
  content: "\e90b"

.icon-pencil:before
  content: "\e90c"

.icon-search:before
  content: "\e90d"

.icon-plus:before
  content: "\e90e"

.icon-medium:before
  content: "\e90f"

.icon-minus:before
  content: "\e910"

.icon-pregunta:before
  content: "\e911"

.icon-telegram:before
  content: "\e912"

.icon-twitter:before
  content: "\e913"

.icon-clock:before
  content: "\e914"

.icon-phone:before
  content: "\e942"

.icon-guild:before
  content: "\e915"

.icon-coinmarketcap:before
  content: "\e890"

.icon-youtube:before
  content: "\e943"

.icon-warning:before
  content: "\e916"

.icon-x:before
  content: "\e918"

.icon-share:before
  content: "\e917"

.icon-burger:before
  content: "\e919"

.icon-external_window:before
  content: "\e891"

.icon-tiktok:before
  content: "\e91a"

.icon-instagram:before
  content: "\ea92"

.icon-linkedin:before
  content: "\eac9"

.icon-github:before
  content: "\eab0"
