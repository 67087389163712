@import 'src/styles/core.sass'
[id="section-sticky"]
  display: none
  position: fixed
  pointer-events: none
  top: 0
  left: 0
  right: 0
  z-index: 1
  height: 100vh
  @supports (height: 100dvh)
    height: 100dvh
  picture
    filter: drop-shadow(0px 0px 60px rgba(#000, 0.5))
    transition: all 0.3s ease-in-out
    &.glow
      filter: drop-shadow(0px 0px 60px rgba(#FFFCE8, 0.6))
  &.enter
    display: block
