@import 'src/styles/core.sass'
[id="parallax"]
  picture
    margin: 0
  .layer
    position: absolute
    z-index: 10
    height: 70vh
    pointer-events: none
    display: none
    +breakpoint(laptop)
      display: block
      max-width: 45vw
      height: 70vh
    +breakpoint(desktop)
      height: 75vh
    picture
      display: block
      height: 100%
      img
        height: 100%
        display: block

  [id="layer1"]
    right: 0
