@import 'src/styles/core.sass'
.language-selector-wrapper
  border-radius: 7px
  border: 1px solid $gray
  background-color: $white
  display: flex
  align-items: center
  position: relative
  max-width: 100px
  box-sizing: border-box
  white-space: nowrap
  cursor: pointer
  overflow: hidden
  .icon
    padding: 7px
    position: absolute
    right: 0px
    pointer-events: none
    margin: 0
    &.icon-angle_down
      font-size: $font-size-xs
      margin: 0
    &.icon-angle_right
      font-size: $font-size-l

  select
    +appearance(none)
    border: none
    margin: 0
    outline: none

    font-weight: bold
    width: 100%
    padding: 10px 20px 11px 10px
    background-color: transparent
    cursor: pointer
    font-size: $font-size-s * 1.1
    @extend %ellipsis
    option
      color: $dark
      background-color: $white

.savings, .intro, .arcade
  .language-selector-wrapper
    border: 1px solid rgba($gray, 1 )
    color: $black
    background-color: $light
    select
      color: $black
      background-color: $light
      option
        color: $dark
