@import 'src/styles/core.sass'
[id="landing-about"]
  background-color: $white
  padding: 5vh 0
  .about-card
    box-shadow: 0px 5px 20px #E4E4E4
    border-radius: 10px
    padding: 30px
    max-width: 720px
    margin: auto
    text-align: center
    text-wrap: balance
    h1
      margin-top: 0
      font-weight: 100
      strong
        font-weight: bold
    .row
      display: flex
      margin: 5px 0
      padding: 6px
      border: 1px solid $medium-gray
      border-radius: 10px
    form
      margin-top: 40px
    button
      color: $white
      border: none
      background-color: $layer-blue
      border-radius: 7px
      padding: 10px 24px
      font-weight: 500
      min-width: 140px
      text-transform: capitalize
      +event
        background-color: darken($layer-blue, 15%)
      &:disabled
        background-color: $layer-blue
    input
      margin: 0
      background-color: $white
    +breakpoint(laptop)
      padding: 60px
    +breakpoint(desktop)
      padding: 70px
  .error
    .row
      border: 1px solid $red
      button
        background-color: darken($red, 15%)
  .done
    .row
      border: 1px solid $layer-celest
      button
        background-color: $layer-celest