@import 'src/styles/core.sass'
[id="landing-menu"]
  .icon
    color: $layer-marine
    font-size: $font-size-l
  > button
    z-index: 961
    position: relative
    border-radius: 8px
    padding: 7px
    background-color: $transparent
    border: none
  .nav-container
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 960
    padding: 60px 30px 30px
    transition: all 0.3s cubic-bezier(0.25, 0.45, 0.45, 0.95)
    opacity: 0
    transform: translateX(20%)
    pointer-events: none
    background-image: url('/images/menu/menu-bg.jpg')
    background-position: center
    background-size: cover
    overflow-y: scroll
    @supports (background-image: url('/images/menu/menu-bg.webp'))
      background-image: url('/images/menu/menu-bg.webp')
    display: flex
    flex-direction: column
  [id="landing-navigation"]
    flex: 1 1 auto
    padding: 20px 0
    ul
      display: flex
      flex-direction: column
      gap: 6px
      a
        font-weight: 500
        line-height: 2.5rem
        font-size: 20px
        padding: 5px 0
        display: inline
      li:last-child
        >a
          display: flex
          gap: 0.5rem
          align-items: center
          .icon
            transition: transform 0.5s ease
            font-size: 0.75rem
            line-height: 0.75rem
        #header-about
          display: none
        &:focus-within
          .icon
            transform: rotate(180deg)
          #header-about
            display: flex
            flex-direction: column
            .icon
              font-weight: bold
            a
              font-weight: 400
              line-height: 1.5rem
              font-size: 18px
    +breakpoint(tablet)
      a
        font-size: $font-size-l
  .symbol-image
    +size(40px)
  .launch-button
    background-color: $layer-marine
  .more-auBNB-button
    color: white
    display: block
    max-width: 100%
    margin-top: 10px
    width: 100%
  &.menu-open
    .nav-container
      +size(100%)
      pointer-events: auto
      opacity: 1
      transform: translateX(0)
      height: 100vh
      > a
        text-align: center
        padding: 1.25rem 0
