@import 'src/styles/core.sass'
[id="landing-partners"]
  padding: 10vh 0
  background-image: linear-gradient(180deg, #F3F3F3 0%, $white 100%)
  text-align: center
  h1
    text-align: center
    margin-bottom: 10vh
    padding: 0 40px
    font-size: $font-size-xl * 1.2
    transition: all 0.4s ease-in-out
    color: $layer-blue
    font-weight: 100
    strong
      color: $base-font-color
      font-weight: bold
    +breakpoint(laptop)
      opacity: 0
      font-size: $font-size-xxl
      transform: translateY(-20%)
    +breakpoint(desktop)
      font-size: $font-size-xxxl
  > .container
    display: grid
    gap: 30px
    grid-template-columns: repeat(2, 1fr)
    .logo-card
      height: 65px
      display: flex
      align-items: center
      justify-content: center
      +breakpoint(laptop)
        height: 80px
    .oddiyanaVentures, .btw, .sushi, .gmx, .balancer, .reaper, .oddiyānaventures, .yearn
      img
        max-height: 100%
    img
      display: block
      max-height: 100%
      max-width: 130px
      object-fit: contain
      max-height: 54px
      filter: grayscale(90%)
    +breakpoint(tablet)
      grid-template-columns: repeat(3, 1fr)
      gap: 50px
      img
        max-width: 200px
    +breakpoint(laptop)
      grid-template-columns: repeat(4, 1fr)

  .logo-card
    transition: all 0.4s ease-in-out
    +breakpoint(laptop)
      opacity: 0
      transform: translateY(-20%)
  .gbv
    img
      transform: scale(2)
  &.enter
    .logo-card, h1
      opacity: 1
      transform: translateY(0)
