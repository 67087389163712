@import 'src/styles/core.sass'
div.clouds-wrapper
  overflow: hidden
  display: block
  position: absolute
  left: -1px
  right: -1px
  height: 70px
  z-index: 101
  -webkit-mask-size: 100% 100%
  mask-size: 100% 100%
  &.cloud-position-top
    top: -3px
  &.cloud-position-bottom
    bottom: -3px

  &.cloud-type-1
    -webkit-mask-image: url('/vectors/clouds/clouds-1.svg')
    mask-image: url('/vectors/clouds/clouds-1.svg')
  &.cloud-type-2
    -webkit-mask-image: url('/vectors/clouds/clouds-2.svg')
    mask-image: url('/vectors/clouds/clouds-2.svg')
  &.cloud-type-3
    -webkit-mask-image: url('/vectors/clouds/clouds-3.svg')
    mask-image: url('/vectors/clouds/clouds-3.svg')
  &.cloud-type-4
    -webkit-mask-image: url('/vectors/clouds/clouds-4.svg')
    mask-image: url('/vectors/clouds/clouds-4.svg')
  &.cloud-type-5
    -webkit-mask-image: url('/vectors/clouds/clouds-5.svg')
    mask-image: url('/vectors/clouds/clouds-5.svg')
  &.cloud-type-6
    -webkit-mask-image: url('/vectors/clouds/clouds-6.svg')
    mask-image: url('/vectors/clouds/clouds-6.svg')
  +breakpoint(laptop)
    height: 15vh