@import 'src/styles/core.sass'
[id="landing-ai"]
  background-color: $white
  position: relative
  z-index: 10
  padding: 70px 0
  .container
    > h1
      font-weight: normal
      text-align: center
      max-width: $mobile
      @extend %push-auto
      transition: all 0.4s ease-in-out
    +breakpoint(laptop)
      > h1
        font-size: 45px
        transition-delay: 0.25s
        text-align: left
        max-width: 100%
        margin-left: 0
        opacity: 0
        transform: translateY(-20%)
      .button
        max-width: max-content
        transition-delay: 0.5s
    .grid-ai
      display: grid
      gap: 20px
      margin: 14vh 0 0
      + a
        margin: 40px auto
        color: white
      +breakpoint(laptop)
        grid-template-columns: repeat(2, minmax(0, 1fr))

        + a
          font-size: $font-size-l
          gap: 10px
          img
            padding-top: 0
            height: 30px
            padding-bottom: 1px
      &.enter article
        opacity: 1
        transform: translateY(0%)
    article
      height: 100%
      border-radius: 12px
      border: 1px solid #D9D9D9
      padding: 20px
      text-align: center
      transition: all 0.3s ease-in-out
      max-width: 670px
      margin: auto
      width: 100%
      h2
        margin-top: 0
        text-wrap: balance
        font-weight: 100
        strong
          font-weight: 600
      img
        max-height: 30vh
      +breakpoint(laptop)
        transform: translateY(-20%)
        opacity: 0
        display: flex
        align-items: center
        gap: 10px
        padding: 40px
        text-align: left
        .info
          flex: 1 1 auto
        h2
          margin-bottom: 2em
        p
          font-size: 20px
        img
          max-width: 45%
          flex: 0 0 auto
  &.enter
    h1
      opacity: 1
      transform: translateY(0)
