@import 'src/styles/core.sass'
.language-selector-header
  > div
    box-sizing: border-box
    overflow: hidden
    display: flex
    align-items: center
    max-width: max-content
    cursor: pointer
    list-style: none
    &::-webkit-details-marker, &::marker
      display: none
  > div
    gap: 10px
    color: $base-font-color
    border: 1px solid rgba($base-font-color, 0.35)
    border-radius: 8px
    background-color: $white
    padding: 6px 10px
    > span
      color: #242979
      font-weight: 800
    &:hover
      border-color: #A4A0A0
    +breakpoint(laptop)
      padding: 8px 10px
  .icon-angle_down
    font-size: $font-size-s
    font-weight: bold
    margin: 0
    color: #242979
  h1
    font-size: $font-size-l
    margin: 10px 0 20px
  +breakpoint(mobile)
    h1
      font-size: $font-size-m
  img
    +size(24px)
  .language-list
    li
      background-color: $white
