@import 'src/styles/core.sass'
[id="landing-masthead"]
  position: fixed
  top: 0px
  left: 0
  right: 0
  z-index: 950
  transition: all 0.3s ease
  align-items: flex-start
  &.hide
    pointer-events: none
    visibility: hidden
  &.blue
    background-color: #51CAFF
    top: 0
    .center a, .icon-burger
      color: $white
    .launch-button
      background-color: $layer-blue
      &:hover
        background-color: #026EEF
  &.black
    background-color: rgba(243, 243, 243, 0.5)
    backdrop-filter: blur(3px)
    top: 0
  &.white, &.default
    background-color: $layer-marine
    top: 0
    .center a, .icon-burger
      color: $white
    .launch-button
      background-color: $layer-blue
      &:hover
        background-color: #132330
  &.default
    background-color: $transparent
  &.white, &.blue, &.default
    .center
      ul
        li:last-child
          img
            filter: invert(100%) sepia(100%) saturate(17%) hue-rotate(240deg) brightness(104%) contrast(100%)
  .container
    display: flex
    justify-content: space-between
    padding-top: 10px
    padding-bottom: 10px
  .link
    display: block
  .left-side
    .link
      max-height: 36px
    img
      max-height: 36px
  .launch-button
    background-color: $layer-marine
  .center
    flex: 1 1 auto
    display: grid
    align-items: center
    ul
      display: none
      +breakpoint(laptop)
        display: flex
        gap: 3%
        justify-content: center
        font-size: 13px
      +breakpoint($minWidth: 1280px)
        font-size: $font-size-m
        gap: 5%
      li
        >a:hover
          font-weight: 500
        &:last-child
          position: relative
          cursor: pointer
          >a
            display: flex
            gap: 0.5rem
            align-items: center
            .icon
              transition: transform 0.5s ease
              font-size: 0.75rem
          #header-about
            display: none
            background-color: $white
            border-radius: 8px
            padding: 1rem
          &:focus-within
            >a
              .icon
                transform: rotate(180deg)
            #header-about
              display: flex
              flex-direction: column
              gap: 1rem
              position: absolute
              top: 40px
              left: 50%
              transform: translateX(-50%)
              width: max-content
              box-shadow: 0px 4px 15px rgba(0,0,0,0.07)
              > a
                font-weight: 400
                padding: 0.5rem 1.5rem
                color: $black
                &:hover
                  background-color: #E5FAFF
              &::before
                content: ''
                position: absolute
                top: 0
                left: 50%
                width: 0
                height: 0
                border: 8px solid transparent
                border-bottom-color: $white
                border-top: 0
                margin-left: -8px
                margin-top: -8px
  .right-side
    flex: 0 0 auto
    display: flex
    align-items: flex-start
    gap: 10px
    .language-selector-header, .launch-button, .more-auBNB-button
      font-size: 13px
      +breakpoint($minWidth: 1280px)
        font-size: $font-size-m