@import 'src/styles/core.sass'
.language-selector-dialog
  box-sizing: border-box
  border: 1px solid rgba($layer-blue, 0.25)
  border-radius: 10px
  top: 50vh
  translate: 0 -50%
  padding: 0
  overflow: hidden
  min-width: 240px
  background-color: $white
  padding: 16px
  header
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 10px
  .icon
    cursor: pointer
  h1
    margin: 0
    font-size: $font-size-m
  ul
    border-top: 1px solid tint($layer-blue, 85%)
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 10px
    padding-top: 20px
  li
    font-size: $font-size-m
    padding: 5px 20px
    display: flex
    gap: 10px
    align-items: center
    cursor: pointer
    border-radius: 6px
    background-color: tint($layer-blue, 92%)
    &.selected
      box-shadow: inset 0 0 0 1px $layer-blue
      background-color: tint($layer-blue, 85%)
  img
    height: 18px
