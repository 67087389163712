@import 'src/styles/core.sass'
section[id="landing-explore-bots"]
  &.landing-section.landing-section
    padding: 0
  .container
    position: relative
    z-index: 10
    padding-top: 100px
    +size(100%)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    img
      max-height: 35vh
      margin: 30px auto
    +breakpoint(laptop)
      flex-direction: row
      > .content
        flex: 0 0 50%
      img
        max-width: 500px
        max-height: 65vh
        display: block
        opacity: 0
        transform: translateY(30%)
        transition: all .75s ease-in-out
      em
        opacity: 0.75
        text-align: center
  > section
    position: relative
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    height: calc(100vh + 100px)
    @supports (height: 100dvh)
      height: calc(100dvh + 100px)
    &[id="bot-ress"]
      background-image: url('/images/landing/bots/ress-bg.jpg')
    &[id="bot-auto"]
      background-image: url('/images/landing/bots/auto-bg.jpg')
      .content-right
        order: -1
      +breakpoint(laptop)
        .mono-space
          line-height: 1.3
        .content-right
          order: 0
    .buttons
      display: flex
      justify-content: center
      gap: 10px
    .buttons-ress
      .button-visit
        background-color: #1269FF
    .buttons-auto
      .button-visit
        background-color: #FFA600
    .button-buy
      background-color: #0F2B41
    .button
      color: $white
      border: none
      border-radius: 8px
      font-weight: 600
      padding-left: 20px
      padding-right: 20px
    h1
      margin-top: 0
      text-wrap: balance
    h1, p, .buttons
      text-align: center
      max-width: $mobile
      @extend %push-auto
      transition: all 0.6s ease-in-out
      +breakpoint(laptop)
        opacity: 0
        transform: translateY(-20%)
    +breakpoint(laptop)
      h1
        font-size: 45px
        transition-delay: 0.2s
      h1, p, .buttons
        text-align: left
        max-width: 100%
        margin-left: 0
      .buttons
        max-width: max-content
        transition-delay: 0.5s
      p
        transition-delay: 0.35s
    &.enter
      h1, p, .buttons
        opacity: 1
        transform: translateY(0)
      img
        opacity: 1
        transform: translateX(0)