@import 'src/styles/core.sass'
$footer-font: Inconsolata, $base-font-family
[id="colophon"]
  background-color: $white
  font-family: $footer-font
  position: relative
  h1
    font-size: $font-size-m
    padding-bottom: 5px
  .icon
    color: $layer-blue
    font-size: $font-size-xxl
  .social-links
    gap: 25px
    padding-bottom: 10px
    +breakpoint(mobile)
      gap: 16px
      a
        font-size: 24px
  .link
    display: block
    width: max-content
    padding: 5px 0
    &:hover
      font-weight: 500
      color: #1269FF
  .row
    display: flex
    gap: 10px
    flex-wrap: wrap
    padding: 10px 0
  .row-top, .row-center
    justify-content: space-between
  .row-top
    border-bottom: 1px solid $medium-gray
    align-items: flex-end
    justify-content: flex-start
    a
      padding: 0
      line-height: 1
    img
      max-height: 32px
    +breakpoint(tablet)
      justify-content: space-between
      img
        max-height: 44px
    +breakpoint($maxWidth: 768px)
      img
        display: none
  .row-center
    padding: 30px 0
  .row-bottom
    border-top: 1px solid $medium-gray
    padding: 20px 0
    justify-content: space-between
    > div
      flex: 1
      align-items: center
    img
      max-height: 28px
      margin-right: 16px
    .button
      border-color: $medium-gray
      color: $base-font-color
      background-color: $transparent
      border-radius: 8px
  .actions
    display: flex
    gap: 10px
    align-items: flex-start
  .columns-wrapper
    section
      h1
        font-family: $footer-font
  .launch-button
    padding: 8px 16px
  +breakpoint(laptop)
    .columns-wrapper
      display: flex
      gap: 70px
