@import 'src/styles/core.sass'
.launch-button
  border-radius: 8px
  font-weight: 600
  padding: 10px 16px
  border: none
  &.dark-theme
    color: #0A0F13
    background-image: linear-gradient(216deg, #17FEA3 0%, #70F920 100%)
  &.light-theme
    color: $white
    background-color: $blue
    +event
      background-color:  #0154E4