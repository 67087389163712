// ---------TYPOGRAPHY---------
$base-font-family: 'Inconsolata', monospace
$heading-font-family: 'Public Sans', Helvetica, Arial, Sans, Liberation Sans, sans-serif
// --Font Sizes--
// $base-font-size: 1rem
// $font-size-xs: $base-font-size * 0.625  // 10px
// $font-size-s: $base-font-size * 0.75    // 12px
// $font-size-m: $base-font-size * 1       // 18px
// $font-size-l: $base-font-size * 1.250   // 20px
// $font-size-xl: $base-font-size * 1.5    // 24px
// $font-size-xxl: $base-font-size * 2     // 32px
// $font-size-xxxl: $base-font-size * 3    // 48px

// --Line height--
// $base-line-height: 1.4
// $heading-line-height: 1.2

// ---------OTHER SIZES---------
// $base-border-radius: 5px
// $base-spacing: $base-line-height * 1em
// $small-spacing: $base-spacing / 2
// $base-z-index: 0

// ---------COLORS---------
$red: #FF003D
$light-black: #2B2828
$dark-grey: #3A3737
$mid-black: #131414
$dark-black: #020202
$blue-btn: #1269FF
$dark-blue: #0F2B41
$white: #FFFFFF
$light-gray: #FAFAFA
$medium-gray: #DEDEDE
$black: #120021
$dark-gray: #888888
$gray: #C0C0C0
$violet: #310072
$blue: #4088FC

$dark: #2C0350
$light: #F7F5FF

$gradient-blue: linear-gradient(180deg, #066AFF 0%, #10A9FF 100%)
$gradient-purple: linear-gradient(180deg, #8000F0 0%, #AF54FF 100%)

$dark-grey-deposit:  #3A3A3A
$layer-blue: #2D8AF9
$layer-celest: #47B6F5
$layer-ultra-marine: #000916
$layer-marine: #0F2B41
$layer-red: #F83427
$layer-orange: #FFA01F
$layer-yellow: #FABC1E

$base-font-color: $layer-marine
$action-color: $base-font-color

$mobile-primary-color: #00B412

// ---------Background---------

