@import 'src/styles/core.sass'
[id="not-found"].page
  position: relative
  background-image: url('/images/error/error_bg.jpg')
  background-position: center
  background-size: cover
  color: $white
  display: flex
  align-items: center
  height: 100vh
  font-family: 'Public Sans', $base-font-family
  @supports (background-image: url('/images/error/error_bg.webp'))
    background-image: url('/images/error/error_bg.webp')
  h1, h2, h3, h4, h5, h6
    font-family: 'Public Sans', $base-font-family
  header
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1111
    padding: 10px 0
    .container
      justify-content: flex-start
      display: flex
  .logo
    height: 40px
  .split
    align-items: center
  .item.left
    text-align: center
    +breakpoint(laptop)
      text-align: left
  .item.right
    display: none
    position: relative
    +breakpoint(laptop)
      display: block
  h1
    font-size: $font-size-xxxl * 3
    line-height: 1
    margin: 0
  .button
    flex: 1 1 auto
    border: none
    border-radius: 10px
    font-size: $font-size-l
    font-weight: 500
    color: $white
    padding: 12px 24px
    background-color: $layer-blue
    margin-top: 4vh
  .mono-space
    font-family: 'Inconsolata', monospace
    font-size: $font-size-l
    font-weight: 400
    text-wrap: balance
    +breakpoint(laptop)
      font-size: $font-size-xl
    +breakpoint(desktop)
      font-size: $font-size-xxl
  .cube-wrapper
    position: relative
    .cube
      position: relative
      z-index: 1
  .shadow
    position: absolute
    top: 50%
    right: 0
    left: 0
    mix-blend-mode: hard-light
    opacity: 0.7
