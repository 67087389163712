@import 'src/styles/core.sass'
.more-auBNB-button
  border-radius: 8px
  font-weight: 600
  padding: 10px 16px
  border: none
  color: $white
  background-color: $layer-orange
  display: flex
  align-items: center
  justify-content: center
  gap: 6px
  width: max-content
  img
    padding-top: 3px
    height: 24px
  +event
    background-color: #DC8F00
    color: white
