@import 'src/styles/core.sass'
[id="landing-hero-banner"]
  overflow: hidden
  position: relative
  height: 200vh
  background-color: $layer-marine
  @supports (height: 200dvh)
    height: 200dvh
  +breakpoint(laptop)
    height: 260vh
  [id="background-banner"]
    // animation: shadow 1.5s ease-in-out both
    box-shadow: inset 0 0 0 1000px #00000066
    background-image: url('/images/landing/new-hero-banner/new-hero-banner.jpg')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    height: 105vh
    position: relative
    @supports (height: 100dvh)
      height: 105dvh
    &::before
      display: block
      content: ''
      position: absolute
      bottom: -1px
      left: 0
      right: 0
      height: 20vh
      z-index: 1
      background-image: linear-gradient(transparent, #F2B125)
    .wrapper
      color: $white
    .content-left
      animation: fadeEnter .8s ease-in-out both 0.4s, move .8s ease-in-out both 0.4s
  .container
    position: relative
    z-index: 10
    padding-top: 16px
    +size(100%)
    +breakpoint(laptop)
      padding-top: 0
      display: flex
      align-items: center
      .content
        flex: 0 0 50%
      img
        max-width: 500px
        max-height: 55vh
        display: block
        margin: auto
  .wrapper
    margin: 18vh auto 0 auto
    max-width: 420px
    text-align: center
    +breakpoint(laptop)
      margin: 20vh 0 0 0
      max-width: $phablet
    strong
      display: block
    .buttons
      display: flex
      gap: 10px
      flex-direction: column
      align-items: center
      +breakpoint(laptop)
        padding-top: 16px
        flex-direction: row
      .discover-ai-button, .launch-button
        justify-content: center
        display: flex
        color: white
        min-width: 200px
    h1
      margin: 0
      line-height: 1
      text-wrap: balance
      font-size: 2.2rem
    p
      margin: 1rem auto
      text-wrap: balance
    .discover-ai-button, .launch-button
      font-size: 15px
    +breakpoint(tablet)
      h1
        font-size: $font-size-xxxl
      p
        max-width: 100%
        margin: 1rem 0
        font-size: $font-size-l
    +breakpoint(laptop)
      margin-top: 0
      text-align: left
    +breakpoint(desktop)
      h1
        font-size: $font-size-xxxl * 1.3
      p
        font-size: $font-size-xl
      .discover-ai-button, .launch-button
        font-size: $font-size-l
  .content-left
    min-height: 45vh
    @supports (height: 50dvh)
      min-height: 45dvh
    +breakpoint(laptop)
      min-height: 0

  .content-right
    animation: float 3.95s ease-in-out .375s infinite, fadeEnter 1s ease-in-out .16s both
    > picture
      display: block
      text-align: center
      margin: auto
      > img
        max-width: 35vh
        @supports (height: 50dvh)
          margin-top: 3dvh
          max-width: 35dvh
        +breakpoint(tablet)
          max-width: 40vh
        +breakpoint(laptop)
          max-width: 100%
          margin-top: 0

@keyframes float
  0%
    transform: translateY(0)
  50%
    transform: translateY(-1.5%)
  100%
    transform: translateY(0)

@keyframes move
  0%
    transform: translateY(-10%)
  100%
    transform: translateY(0)

@keyframes shadow
  0%
    box-shadow: inset 0 0 0 0px #00000066
  100%
    box-shadow: inset 0 0 0 1000px #00000066
