@import 'src/styles/core.sass'
[id="token-details"].page
  position: relative
  background-color: $layer-marine
  background-image: url(/images/landing/token-details/noise.png)
  background-size: cover
  scroll-behavior: smooth
  header
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1111
    padding: 10px 0
    background-color: $layer-ultra-marine
    .container
      justify-content: flex-start
      display: flex
      gap: 10px
      justify-content: space-between
    .button
      color: white
      background-color: $layer-blue
    .logo
      height: 40px
  > .container
    padding: 80px 30px 10px
    max-width: $laptop
    margin: auto
    color: white
    .breadcrumb
      padding: 20px 0 0px
      a
        color: white
        display: flex
        align-items: center
        gap: 5px
      .icon
        font-size: 12px
      +breakpoint(tablet)
        a
          font-size: 18px
        .icon
          font-size: 14px
    h1
      text-wrap: balance
      +breakpoint(tablet)
        font-size: 56px
    .token-info-list
      display: grid
      gap: 30px
      > li:nth-child(even)
        .right-item
          order: -1
          img
            right: auto
            left: -6%
    article
      padding: 20px 30px
      border-radius: 13px
      color: $white
      background-color: #081626
      box-shadow: 0 4px 4px rgba($black, 0.25)
      +breakpoint(tablet)
        min-height: 275px
        display: flex
        gap: 30px
        justify-content: space-between

      h2
        font-weight: 600
        +breakpoint(tablet)
          font-size: 27px

      p
        text-wrap: balance
        +breakpoint(tablet)
          font-size: 18px
    .left-item
      flex: 1 1 auto
    .right-item
      text-align: center
      position: relative
      +breakpoint(tablet)
        min-width: 360px
    img
      max-height: 260px
      +breakpoint(tablet)
        max-height: 360px
        position: absolute
        top: -25%
        width: 100%
        right: -15%
