@import 'src/styles/core.sass'
[id="landing-community"]
  background-image: url('/images/landing/community/community-bg.jpg')
  background-position: top
  background-size: cover
  color: $white
  @supports (background-image: url('/images/landing/community/community-bg.webp'))
    background-image: url('/images/landing/community/community-bg.webp')
  .community-card
    max-width: 686px
    background-color: $layer-marine
    padding: 30px
    border-radius: 28px
    text-align: center
    margin: 10vh auto
    position: relative
    transition: all 0.3s ease-in-out
    +breakpoint(laptop)
      opacity: 0
      transform: translateY(20%)
    h1
      text-wrap: balance
      margin-top: 0
      font-weight: 600
    +breakpoint(laptop)
      padding: 50px
      text-align: left
      h1
        font-size: $font-size-xxxl
    +breakpoint(desktop)
      padding: 70px
      max-width: 886px
      h1
        font-size: $font-size-xxxl * 1.25
  .community-grid
    display: grid
    max-width: $tablet
    margin: auto
    gap: 40px
    position: relative
    padding: 30px 0 0
    +breakpoint(tablet)
      display: flex
    .icon
      font-size: $font-size-xxl
      color: $layer-blue
      +breakpoint(desktop)
        font-size: $font-size-xxl * 1.2
    a
      color: $white
      display: block
      transition: all 0.5s ease-in-out 0.6s
      +breakpoint(laptop)
        opacity: 0
        transform: translateY(20%)
    h2
      font-size: $font-size-m
      text-transform: uppercase
      margin: 0
      margin-top: 14px
    p
      margin: 5px 0
      color: $white
      font-size: $font-size-m
  .social-links
    position: absolute
    left: 50%
    translate: -50% 60px
    transition: all 0.3s ease-in-out 0.15s
    a
      color: $white
      font-size: $font-size-xl
    +breakpoint(laptop)
      display: block
      top: -10px
      right: -60px
      left: auto
      translate: 0
      opacity: 0
      transform: translateY(-20%)
      a
        display: block
        padding: 5px
        font-size: $font-size-xl * 1.2
        line-height: 1.25
        font-weight: normal
  &.enter
    .community-card, .social-links
      opacity: 1
      transform: translateY(0)
    .community-grid
      a
        opacity: 1
        transform: translateY(0)

