html, body, [id="root"], [id="app"]
  height: 100%
  width: 100%
  margin: 0
  padding: 0
  box-sizing: border-box
[id="app"]
  overflow: hidden
  &::-webkit-scrollbar, ::-webkit-scrollbar
    +size(6px)
    cursor: pointer
    padding: 2px
  &::-webkit-scrollbar-track, ::-webkit-scrollbar-track
    background: rgba($gray, 0.5)
  &::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb
    background: $gray
    cursor: pointer
.external-page
  font-family: 'Public Sans', $base-font-family
  h1, h2, h3, h4, h5, h6
    font-family: 'Public Sans', $base-font-family
  .mono-space
    font-family: 'Inconsolata', monospace
    +breakpoint(laptop)
      font-size: $font-size-l
    +breakpoint(desktop)
      font-size: $font-size-xl
.pages-transition-group
  position: relative
  +size(100%)
  > div
    height: 100%
  .page
    position: absolute
    height: 100%
    width: 100vw
    margin: auto
    padding: 0.1px
    overflow: auto
    background-color: $white
    ::-webkit-scrollbar-track
      border-radius: 8px
  .modal-background
    z-index: 9
    padding: 20px
    animation: fadeEnter 0.3s ease-in-out
  .modal-foreground
    height: 100%
    width: 100%
    max-height: 100%
    max-width: 100%
    border-radius: 10px
.only-desktop
  display: none
  +breakpoint(laptop)
    display: inline-block
.only-mobile
  display: inline-block
  +breakpoint(laptop)
    display: none
.mobile-container
  @extend %push-auto
  max-width: 720px
  padding: 0 10px

@keyframes fadeEnter
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes blink
  0%
    opacity: 1
  50%
    opacity: 0
  100%
    opacity: 1
