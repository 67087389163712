@import 'src/styles/core.sass'
[id="token-info-banner"]
  position: relative
  padding: max(15vh, 80px) 0
  text-align: center
  color: white
  .container
    max-width: 960px
    margin: auto
  h3
    font-size: 34px
    font-weight: normal
    margin-bottom: 2em
    strong
      color: #F9A72D
      font-weight: 900
  [id="info-banner-grid"]
    display: grid
    gap: 10px
    +breakpoint(tablet)
      gap: 40px
      grid-template-columns: repeat(4, minmax(0, 1fr))
      span
        font-size: 26px

  .button
    background-color: #081626
    border-radius: 20px
    img
      scale: 0.8
      transition: scale 0.2s ease-in-out
    +event
      background-color: $layer-ultra-marine
      img
        scale: 1
    span
      display: block
      color: white
