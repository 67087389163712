@import 'src/styles/core.sass'
[id="landing-token"]
  background-color: #F3F3F3
  .split
    min-height: 70vh
    align-items: center
  .right
    text-align: center
    img
      opacity: 0
      transform: translateY(20%)
      transition: all 0.3s ease-in-out 0.25s

  .left
    h1 strong
      font-weight: 100
    h1, p, .button
      text-align: center
      max-width: $mobile
      @extend %push-auto
      transition: all 0.4s ease-in-out
      +breakpoint(laptop)
        opacity: 0
        transform: translateY(-20%)
    .button
      max-width: max-content
    +breakpoint(laptop)
      order: 1
      h1
        font-size: 45px
        transition-delay: 0.25s
      h1, p, .button
        text-align: left
        max-width: 100%
        margin-left: 0
      .button
        max-width: max-content
        transition-delay: 0.5s
  .button
    color: $white
    background-color: $layer-blue
    border: none
    padding: 12px 16px
    font-weight: 50
    border-radius: 8px
  &.enter
    h1, p, .button, img
      opacity: 1
      transform: translateY(0)
